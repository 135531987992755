$mysa-blue: #1db2ec;
$mysa-green: #52d1c2;
$mysa-yellow: #f8c735;
$mysa-pink: #E80F8B;
$mysa-red: #FF0000;
$mysa-charcoal: #5f5f5f;

.mysa-blue-text {
  color: $mysa-blue !important;
}

.mysa-pink-text {
  color: $mysa-pink;
}

.mysa-green-text {
  color: $mysa-green;
}

.mysa-yellow-text {
  color: $mysa-yellow;
}

.mysa-green {
  background-color: $mysa-green;
}

.mysa-yellow {
  background-color: $mysa-yellow;
}

.mysa-charcoal {
  background-color: $mysa-charcoal;
} 

.mysa-blue {
  background-color: $mysa-blue;
}

.mysa-pink {
  background-color: $mysa-pink;
}

.mysa-gray {
  background-color: #999999;
}

.ant-message-custom-content {
  display: flex;
  align-items: center;
}

.react-tel-input input {
  width: 100% !important;
}

// input:read-only {
//   cursor: not-allowed;
// }

.ant-image-preview-img-wrapper {
  transform: scale(0.5) !important;
}

.ant-image-preview-img {
  margin-left: 25%;
  margin-top: -20%;
}

.ant-image-preview-wrap {
  background-color: #aba6a6;
}

.ant-select-disabled {
  .ant-select-selector {
    background: white !important;
    color: black !important;
  }
}

.pac-target-input {
  width: 100%;
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;

  &:hover {
    border-color: #40a9ff;
    outline: unset;
  }

  &:focus {
    border-color: #40a9ff;
    outline: unset;
  }
}

.anticon {
  position: relative;
  bottom: 3px;
}

.rings {
  background-image: url("../images/ring.png");
  background-repeat: repeat-y;
  height: 275px;
  width: 60px;
  position: absolute;
  top: 45px;
  z-index: 10;
  left: 243px;
}

.ant-image-img {
  display: block;
  width: 100px;
  height: 100px;
}

.inset {
  top: 5.5rem;
  right: 0;
  bottom: 0;
  left: -2rem;
  position: absolute;
}

.inset2 {
  top: 18.5rem;
  right: 0;
  bottom: 0;
  left: 2rem;
  position: absolute;
}

.ant-radio-inner,
.ant-checkbox-inner {
  border-color: black !important;
}

.disabled {
  background-color: lightgray;
}

.ant-checkbox-input {
  cursor: pointer !important;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  border: 1px solid #dedede;
  background: #fbfbfb;
  transition: background 0.3s;
  font-size: 16px;
  font-weight: 500;
  height: 70px;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
  text-align: left;
}

.ant-badge {
  margin-left: 10px;
}

.ant-badge-status-dot {
  width: 12px;
  height: 12px;
}

.ant-picker-calendar .ant-picker-panel .ant-picker-body {
  .ant-picker-content {
    thead {
      text-align: center;
      text-transform: uppercase;
    }
  }
}

.ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper {
  // border-right-color:#13609e;
  // background-color: lightgray;
  color: #fff;
  background: black !important;
  border-color: black !important;
}

.ant-radio-input {
  cursor: pointer !important;
}

.ant-tree-checkbox-inner {
  border-color: #1890ff !important;
}

.date-available {
  color: #52c41a;
  transition: color 0.3s;
}

.date-booked {
  color: #ff4d4f;
}

.available {
  text-align: right;
  float: right;
  font-size: 12px;
}

.ant-drawer-content-wrapper {
  width: 75% !important;
}

.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: white !important;
}

.search-list {
  padding: 10px;
  // background: #fbfbfb;
  //  border: 1px solid #d9d9d9;
  // border-radius: 2px;
}

.search-map {
  padding: 10px;
  // background: #fbfbfb;
  //   border: 1px solid #d9d9d9;
  // border-radius: 2px;
  // height:initial;
}

.search-list2 {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  width: 750px;
  height: 500px;
}

.result-item {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  padding-left: 0px !important;
  padding-right: 0px !important;

  // max-width: 420px;

  .res-image {
    height: 360px;
    // width: 390p;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .icon {
    height: 26px;
    width: auto;
  }

  .item-details {
    min-height: 90px;
  }
}

.overlay-mini-bar {
  position: absolute;
  width: auto;
  top: 325px;
  color: black;
  font-size: 12px;
  display: flex;
  margin-top: -32px;

  .overlay-mini-bar-section {
    display: flex;
    align-items: left;
  }
}

.font-colour {
  color: #5a6a73;
}

.view-book {
  background-color: #fdc61c;
  border-color: #fdc61c;
  color: black;

  &:hover {
    border-color: #fdc61c;
    outline: unset;
    color: black;
    background-color: #fdc61c;
  }

  &:focus {
    border-color: #fdc61c;
    outline: unset;
    color: black;
  }
}

.property-date {
  background-color: white;
  border-color: gray;
  color: black;

  &:hover {
    border-color: grey;
    outline: unset;
    color: black;
    background-color: white;
  }

  &:focus {
    border-color: gray;
    outline: unset;
    color: black;
  }
}

.quick-book {
  background: #05d1c1;
  border-color: #05d1c1;
  color: black;

  &:hover {
    border-color: #05d1c1;
    outline: unset;
    color: black;
    background: #05d1c1;
  }

  &:focus {
    border-color: #05d1c1;
    outline: unset;
    color: black;
  }
}

.share-book {
  background: #fcfbf8;
  border: 2px solid #fdc61c;
  color: black;

  &:hover {
    border-color: #fdc61c;
    outline: unset;
    color: black;
    background: #fcfbf8;
  }

  &:focus {
    border-color: #fdc61c;
    outline: unset;
    color: black;
  }
}

.checkIn {
  position: absolute;
  width: auto;
  top: 325px;
  color: black;
  font-size: 12px;
  display: flex;

  .checkIn-section {
    display: flex;
    align-items: left;
  }
}

.card {
  background-clip: border-box;
  margin-right: 5% !important;
  // border-top-left-radius: '28px';
  // width: '390px';
  // border-top-right-radius: '28px';
  // border-bottom-left-radius: '28px';
  // border-bottom-right-radius: '28px';
  // margin-inline: '10px';
  // margin-bottom: '25px';
  // margin-top: '14px';
  // background-color: '#F2F2F2';
  //border: .5px solid rgba(0, 0, 0, .125);
  // border-radius: 1.5rem;
}

// .card-body {
//   flex: 1 1 auto;
//   min-height: 1px;
//   padding: 1.25rem;
// }

.property-section {
  margin-bottom: 50px;
}

.property-item {
  .property-image {
    height: 350px;
    width: auto; //900px;
    object-fit: cover;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .property-img {
    height: 350px;
    width: auto; //475px;
    object-fit: cover;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .icon {
    height: 20px;
    width: auto;
  }

  .item-details {
    min-height: 90px;
  }
}

.traveler-item {
  .traveler-image {
    height: 400px;
    width: 900px;
    object-fit: cover;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .traveler-img {
    height: 400px;
    width: auto;
    object-fit: cover;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .icon {
    height: 20px;
    width: auto;
  }

  .item-details {
    min-height: 90px;
  }
}

.property-mini-bar {
  background-color: rgba(19, 162, 208, 0.7);
  position: absolute;
  height: 44px;
  width: 100%;
  top: -44px;
  color: white;
  font-size: 12px;
  display: flex;

  .overlay-mini-bar-section {
    display: flex;
    align-items: center;
  }

  img {
    filter: invert(1) brightness(10);
  }
}

.card2 {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
}

.icon {
  width: 50px;
  height: 50px;
  margin: 1px;
}

.icon2 {
  width: 36px;
  height: 36px;
  margin: 1px;
}

.searchIcon {
  width: 25px;
  height: 50px;
  margin: 1px;
}

.amenity-block {
  padding: 30px;
  border: 1px solid #d8d8d8;
  margin: 15px;
  width: 160px;

  img {
    width: 75px;
  }

  p {
    height: 33px;
  }
}

.amenity-block2 {
  padding: 30px;
  border: 1px solid #d8d8d8;
  margin: 15px;
  width: 1190px;
  position: relative;

  img {
    width: 75px;
  }

  p {
    height: 33px;
  }
}

.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #1a1818;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #1a1818;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.travel-mode {
  text-align: center;
  padding-bottom: 15px;

  span {
    margin-left: 10px;
    cursor: pointer;
    font-weight: 500;
  }

  .travel-mode__selected {
    background: #e80f8b;
    padding: 5px 15px;
    color: cornsilk;
  }

  .book-property {
    background-color: #fffcf3;
    border: 1px solid #f6edce;
    margin-top: 40px;
    height: 300px;
  }

  .not-complete {
    background-color: #444;
  }
}

/////////////////////// tabs bar color ////////////////
.ant-tabs-nav {
  background: #f0f0f0;
}

.mysa-sub-menu .ant-tabs-nav .ant-tabs-tab-active {
  background: #fbc91d;
  padding: 10px;
  border-radius: 5px;
  outline: none;
}

.ant-tabs-ink-bar {
  height: 5px;
  background: #000000;
}

///////////////////// menus ////////////////////////
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected {
  color: #000000;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected::after {
  border-bottom: 3px solid #000000;
}

.site-card-border-less-wrapper {
  padding: 30px;
  background: #ececec;
}

.fontDark {
  font-weight: bold;
}

///////////////// ant check box /////////////
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #000000 !important;
  border-color: #ffffff !important;
}
.ant-radio-checked .ant-radio-inner {
  background-color: #000000 !important;
  border-color: #ffffff !important;
}
// ///////////////// ant check box /////////////
// .ant-radio-checked .ant-radio-inner {
//   background-color: #000000 !important;
//   border-color: #ffffff !important;
// }

.marker {
  width: 48px;
  height: 48px;
}

.sharePopertyBox {
  background: #d9d9d9;
  padding-bottom: 40px !important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.6);
}

.essentialInfo ul li:not(:last-child),
.mysaAudits ul li:not(:last-child) {
  margin-bottom: 20px;
}

.bookingListSwiper .swiper-button-prev,
.bookingListSwiper .swiper-button-next {
  color: #908a8a;
  border: 3px solid #908a8a;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding: 15px;
  opacity: 1 !important;
}

.bookingListSwiper .swiper-button-prev {
  left: 0;
}

.bookingListSwiper .swiper-button-next {
  right: 0;
}

.bookingListSwiper .swiper {
  padding: 0 50px;
}

.bookingListSwiper .swiper-button-prev::after,
.bookingListSwiper .swiper-button-next::after {
  font-size: 18px;
  font-weight: bold;
}

a.fancyBtn {
  background: #fac90e;
  padding: 0px 8px;
  height: 36px;
  font-size: 16px;
  text-align: center;
  line-height: 36px;
  border-radius: 10px;
  margin-left: 20px;
  min-width: 180px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 40px;
  left: 0;
  z-index: 99;
}

a.fancyBtn img {
  width: 25px;
  margin-right: 10px;
}

a.fancyBtn::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 20%;
  background-image: url(../images/icons/svg/outside/search.svg);
  width: 25px;
  height: 25px;
  transform: translateY(-50%);
}

.property-list .rdt_Table>div.sc-ivTmOn {
  box-shadow: 0 0 10px #00000033;
  border-radius: 30px;
  padding: 30px;
  max-width: 80%;
  width: 100%;
  margin: 0px auto 20px;
  font-size: 22px;
  font-weight: 700;
  color: #fbca21 !important;
  justify-content: flex-start;
}

// fancybox
div#fancybox-4 {
  max-width: 100%;
  max-height: 100%;
  transform: translate(-30%, -30%);
  top: 30%;
  left: 30%;
}

.fancybox__container {
  background: rgba(24, 24, 27, 0.4);
  padding: 4% 4.9% 4% 4.9%;
}

.fancybox__container .fancybox__toolbar {
  position: relative;
  background: #908a8a;
}

.fancybox__container::before {
  content: "";
  background: #fff;
  top: 50%;
  left: 50%;
  width: 90%;
  height: 80%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.fancybox__container .fancybox__backdrop {
  display: none;
}

.fancybox__container .fancybox__carousel button.carousel__button {
  bottom: 0 !important;
  top: auto;
  border: 4px solid #908a8a;
}

.fancybox__container .fancybox__carousel button.carousel__button.is-next {
  right: 45%;
}

.fancybox__container .fancybox__carousel button.carousel__button.is-prev {
  left: 45%;
}

.fancybox__container .fancybox__carousel button.carousel__button svg {
  stroke: #908a8a;
}

.fancybox__container .fancybox__carousel .fancybox__slide {
  padding: 30px 60px 80px;
}

.card.result-item {
  height: 100%;
}

.result-item #approve-button,
.sharePopertyBox form button#approve-button {
  color: #000 !important;
}

.sharePopertyBox #request-availability .shareAvilBoxImage {
  height: 200px !important;
  object-fit: cover;
}

.propertyContactForm button#approve-button {
  // background: #35bdb4 !important;
  // border-color: #35bdb4 !important;
  // border-radius: 15px !important;
  padding: 0 70px !important;
  width: auto !important;
  display: table;
  background: transparent !important;
  height: 42px !important;
  border-radius: 20px !important;
  box-shadow: 0 3px 10px #2dbdb6 !important;
  border-color: transparent !important;
  color: #000 !important;
}

.mr-btn {
  margin-top: -6px !important;
}

.fancybox__container .fancybox__carousel .fancybox__slide .fancybox__content {
  width: 100% !important;
  height: 100% !important;
}

.fancybox__container .fancybox__carousel .fancybox__slide .fancybox__content img.fancybox__image {
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
}

@media (max-width: 1440px) {
  .result-item .res-image {
    height: 280px;
  }

  #selfCheckIn-button.checkin-reception p {
    text-align: left;
  }

  .result-item .card-body {
    padding: 10px;
  }

  button#share-button .bookingShareProperty,
  button#resend-button .bookingRequestAvailability {
    height: auto !important;
    font-size: 10px !important;
  }

  button#routing-button .bookingViewProperty {
    font-size: 14px;
  }
}

.btn-approval {
  background: #35BDB4 !important;
  height: 49px !important;
  width: 170px !important;
  top: 7169px;
  left: 5778px;
  border-radius: 14px !important;
  font-size: 18px !important;
  color: #FFFFFF !important;
}


.btn-viewProperty {
  background: #FBC91D !important;
  height: 49px !important;
  width: 170px !important;
  top: 7169px;
  left: 5778px;
  border-radius: 14px !important;
  font-size: 18px !important;
  color: #FFFFFF !important;
  box-shadow: 0 3px 10px #FBC91D !important;
  border-color: #FBC91D !important;
}

.btn-approval-property {
  background: #35BDB4 !important;
  height: 49px !important;
  width: 230px !important;
  top: 7169px;
  left: 5778px;
  border-radius: 14px !important;
  font-size: 14px !important;
  color: #FFFFFF !important;
}


.btn-decline {

  background: #FC0C0C !important;
  height: 49px !important;
  width: 200px !important;
  top: 7169px;
  left: 5778px;
  border-radius: 14px !important;
  font-size: 18px !important;
  color: #FFFFFF !important;
  box-shadow: 0 3px 10px #FC0C0C !important;
  border-color: #FC0C0C !important;
}


.btn-comment {
  background: #22AEE2 !important;
  height: 49px !important;
  width: 200px !important;
  top: 7169px;
  left: 5778px;
  border-radius: 14px !important;
  font-size: 18px !important;
  color: #FFFFFF !important;
  box-shadow: 0 3px 10px #22AEE2 !important;
  border-color: #22AEE2 !important;
}


.btn-negotiate {

  background: #E80F8B !important;
  height: 49px !important;
  width: 230px !important;
  top: 7169px;
  left: 5778px;
  border-radius: 14px !important;
  font-size: 14px !important;
  color: #FFFFFF !important;
  box-shadow: 0 3px 10px #E80F8B !important;
  border-color: #E80F8B !important;

  // background: #35BDB4 !important;
  // height: 49px !important;
  // width: 230px !important;
  // top: 7169px;
  // left: 5778px;
  // border-radius: 14px !important;
  // font-size: 14px !important;
  // color: #FFFFFF !important;
}

.btn-review {
  // background: #eaddab !important;
  // height: 45px;
  // border-radius: 14px;
  // margin-right: 30px;
  // // border: 1px solid #ffffff !important;
  // color: #000000 !important;
  // border-color: #eaddab!important;
  // box-shadow: none !important;
  background: transparent !important;
  height: 38px !important;
  border-radius: 20px !important;
  font-size: 14px !important;
  box-shadow: 0px 3px 10px #eaddab !important;
  border-color: transparent !important;
  color: #000 !important;

}


.ck.ck-content ul,
.ck.ck-content ul li {
  list-style-type: inherit;
}

.ck.ck-content ul {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 40px;
}

.ck.ck-content ol,
.ck.ck-content ol li {
  list-style-type: decimal;
}

.ck.ck-content ol {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 40px;
}

.customStyle ul:nth-of-type(1) {
  list-style-type: disc;
}

.customStyle ul:nth-of-type(2) {
  list-style-type: circle;
}

.customStyle ul:nth-of-type(3) {
  list-style-type: square;
}

.customStyle ul:nth-of-type(4) {
  list-style-type: decimal;
}

.customStyle ul:nth-of-type(5) {
  list-style-type: decimal-leading-zero;
}

.customStyle ul:nth-of-type(6) {
  list-style-type: lower-roman;
}

.customStyle ul:nth-of-type(7) {
  list-style-type: upper-roman;
}

.customStyle ul:nth-of-type(8) {
  list-style-type: lower-greek;
}

.customStyle ul:nth-of-type(9) {
  list-style-type: lower-latin;
}

.customStyle ul:nth-of-type(10) {
  list-style-type: upper-latin;
}

.customStyle ul:nth-of-type(11) {
  list-style-type: armenian;
}

.customStyle ul:nth-of-type(12) {
  list-style-type: georgian;
}

.customStyle ul:nth-of-type(13) {
  list-style-type: lower-alpha;
}

.customStyle ul:nth-of-type(14) {
  list-style-type: upper-alpha;
}

.customStyle ul:nth-of-type(15) {
  list-style-type: none;
}

.customStyle ol {
  list-style: none;
  counter-reset: item;
}

.customStyle ol li {
  counter-increment: item;
  margin-bottom: 5px;
}

.customStyle ol li:before {
  margin-right: 10px;
  content: counter(item);
  background: transparent;
  border-radius: 100%;
  color: #000000;
  width: 1.2em;
  text-align: center;
  display: inline-block;
}

.bx_card .ant-card-body {
  padding: 0 !important;
}

.ant-picker-panel-container {
  border-radius: 30px;
}

.programme-card {
  width: 240px;
  height: 185px;
  // margin-left: 10px;
  gap: 0px;
  border-radius: 44px 44px 44px 44px;
  opacity: 0px;
  // top: 570px;
  // left: 90px;
  border: 2px solid #0d6efd;
  // position: fixed;

}

.programme-card2 {
  width: 240px;
  height: 270px;
  // margin-left: 10px;
  gap: 0px;
  border-radius: 44px 44px 44px 44px;
  opacity: 0px;
  // top: 570px;
  // left: 90px;
  border: 2px solid #0d6efd;
  // position: fixed;

}



.programme-card3 {
  width: 240px;
  height: 300px;
  // margin-left: 10px;
  gap: 0px;
  border-radius: 44px 44px 44px 44px;
  opacity: 0px;
  // top: 570px;
  // left: 90px;
  border: 2px solid #0d6efd;
  // position: fixed;

}